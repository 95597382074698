import isNull from 'lodash.isnull'
import isUndefined from 'lodash.isundefined'
import linkify, {
  LinkifyIt,
} from 'linkify-it'
import omitBy from 'lodash.omitby'
import tlds from 'tlds'

const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL
const IS_SERVER = typeof window === 'undefined'

const linkifyIt: LinkifyIt = linkify()
  .tlds(tlds)

export const buildImageUrl = ({
  image,
  w,
  h,
  t = 50,
  l = 50,
  q = 85,
  b = 0,
  g = 0,
}: {
  image: string|undefined
  w: number
  h: number
  t?: number
  l?: number
  q?: number
  b?: number
  g?: number
}): string => {
  if (!image) {
    return ''
  }

  return `${MEDIA_URL}${w}x${h}/${t}/${l}/${image}/quality/${q}/blur/${b}/grayscale/${g}`
}
export function identity<TSelf> (x: TSelf): TSelf {
  return x
}
export const formatNumber = (number: number) => {
  return new Intl.NumberFormat('en-US').format(number)
}
export const isNullOrUndefined = (val: any) => isUndefined(val) || isNull(val)
export const omitNullOrUndefined = (obj: any) => omitBy(obj, isNullOrUndefined)
export const generateRandomString = (length: number): string => {
  let i
  let text

  i = 0
  text = ''

  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (i; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}
export const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
  const digest = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(codeVerifier),
  )

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}
export const extractLinks = (text: string): linkify.Match[] | null => {
  return linkifyIt.match(text)
}
export function hexToRgb (hex: string) {
  const [, h] = hex.split('#')
  const bigint = parseInt(h, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `${r}, ${g}, ${b}`
}
export function getURL (path: string) {
  const baseURL = IS_SERVER
    ? process.env.NEXT_PUBLIC_SITE_URL!
    : window.location.origin
  return new URL(path, baseURL).toString()
}

const URL_RE = new RegExp(
  /^((https?:\/\/)|(?:www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
)
export const isValidUrl = (url: string) => URL_RE.test(url)
const APOSTROPHE_CHAR = '’'

export function possessive (string: string) {
  if (string == '') {
    return string
  }
  const lastChar = string.slice(-1)
  const endOfWord = lastChar.toLowerCase() == 's' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`
  return `${string}${endOfWord}`
}
