'use client'

import React, {
  ReactElement,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import { css, cx } from 'styled-system/css'
import { updateFavorite } from '@/app/actions'
import { useCurrentUser } from '@hooks'
import { useRouter } from 'next/navigation'
import type { UserType } from '@/types/users'

type Props = {
  id: number
  isFavorite: boolean
  isRead: boolean
}

const FavoriteButton = ({
  id,
  isFavorite,
  isRead,
}: Props): ReactElement => {
  const user = useCurrentUser() as UserType
  const router = useRouter()

  const [isMarkedFavorite, setAsFavorite] = useState(isFavorite)

  const onClick = async () => {
    try {
      const response = await updateFavorite(user.id, id)

      if (!response?.errors) {
        const title = !isMarkedFavorite
          ? 'Added to your favorites'
          : 'Removed from your favorites'

        toast.success(title, {
          id: 'favorites',
        })

        setAsFavorite(!isMarkedFavorite)

        await router.refresh()
      } else {
        toast.error('Oops! Something went wrong.', {
          id: 'favorites',
        })
      }
    } catch {
      //
    }
  }

  const favoriteIcon = isMarkedFavorite ? 'fas fa-star' : 'fal fa-star'

  if (!user) {
    return (
      <></>
    )
  }

  return (
    <button
      className={cx('action', 'favorite', styles.metaAction, isRead ? styles.metaRead : '')}
      onClick={onClick}>
      <i className={favoriteIcon}/>
      <span className={styles.metaActionText}>Favorite</span>
    </button>
  )
}

const styles = {
  metaAction: css({
    bg: 'none',
    border: 'none',
    color: 'link',
    cursor: 'pointer',
    fontWeight: '400',
    p: 0,
    _hover: {
      color: 'linkHover',
    },
  }),
  metaActionText: css({
    display: 'none',
  }),
  metaRead: css({
    color: 'textAlt !important',
    fontWeight: '400 !important',
  }),
}

export default FavoriteButton
