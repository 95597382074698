import Avatar from '@components/shared/UserAvatar'
import FavoriteButton from '@components/shared/FavoriteButton'
import Link from 'next/link'
import React, {
  MouseEvent,
  ReactElement,
  useState,
} from 'react'
import moment from 'moment'
import { buildImageUrl } from '@utils/functional'
import { css, cx } from 'styled-system/css'
import { flex, hstack, vstack } from 'styled-system/patterns'
import { useCurrentUser } from '@hooks'
import type { FeedType } from '@/types/feed'

type Props = {
  thread: FeedType
}

const GridItem = ({
  thread,
}: Props) => {
  const {
    cover,
    id,
    is_promoted: isPromoted,
    slug,
    tags,
  } = thread

  const user = useCurrentUser()
  const [showRestricted, setShowRestricted] = useState(false)

  const onShowRestrictedClick = (event: MouseEvent) => {
    event.preventDefault()

    setShowRestricted(!showRestricted)
  }

  const renderCover = (): ReactElement => {
    if (cover) {
      return (
        <div className={cx('cover', styles.cover)}>
          <img
            alt=''
            src={buildImageUrl({ image: cover, w: 1920, h: 1080, q: 100 })}/>
        </div>
      )
    }

    return <></>
  }

  const renderMeta = (): ReactElement => {
    const {
      count,
      date,
      id: threadId,
      is_favorite: isFavorite,
      is_read: isRead,
      last_reply: {
        date: lastReplyDate,
        username: lastReplyUsername,
        user_avatar: lastReplyAvatar,
        user_slug: lastReplyUserSlug,
      },
      title,
      username,
      user_avatar: avatar,
      user_slug: userSlug,
    } = thread
    const lastPage = Math.max(Math.ceil(count / 50), 1)

    return (<div className={cx('meta', styles.meta)}>
      <div className={styles.metaThread}>
        <Avatar
          classNames={styles.avatar}
          image={avatar}/>
        <div className={styles.metaThreadContent}>
          <div className={styles.metaUserWrapper}>
            <Link
              className={cx(isRead ? styles.metaRead : '')}
              href={`/u/${userSlug}`}
              prefetch={false}>
              <strong>{username}</strong>
            </Link> <span className={cx('date', styles.metaDate)}>{moment(date).fromNow()}</span>
          </div>
          <Link
            href={`/s/${slug}/t/${id}`}
            prefetch={false}>
            <h3 className={cx('title', styles.metaTitle, isRead ? styles.metaRead : '')}>{title.replace(/&amp;/g, '&')}</h3>
          </Link>
        </div>
      </div>
      <div className={styles.metaActions}>
        <span className={cx('action', styles.metaNoAction, styles.metaCount, isRead ? styles.metaRead : '')}>
          {
            !isRead ? <i className={cx(styles.dot, 'fa-circle')}/> : null
          }
          <i className='fal fa-comment-lines'/> {count}
          <span className={styles.metaActionText}>Replies</span>
        </span>
        <span className={cx('action', styles.metaNoAction, styles.metaLastReply, isRead ? styles.metaRead : '')}>
          <span className={styles.lastReplyUser}>
            <Avatar
              classNames={cx(styles.avatar, styles.lastReplyAvatar)}
              image={lastReplyAvatar}
              size='16'/>
            <Link
              className={cx(styles.metaUsername, isRead ? styles.metaRead : '')}
              href={`/u/${lastReplyUserSlug}`}
              prefetch={false}>
              <strong className={styles.lastReplyUsername}>{lastReplyUsername}</strong>
            </Link> <span className={cx(styles.metaDate, styles.lastReplyDate)}>{moment(lastReplyDate).fromNow()}</span>
          </span>
        </span>
        <FavoriteButton
          id={threadId}
          isFavorite={isFavorite}
          isRead={isRead}/>
        <Link
          className={cx('action', styles.metaAction, isRead ? styles.metaRead : '')}
          href={`/s/${slug}/t/${id}/page/${lastPage}`}
          prefetch={false}>
          <i className='fal fa-arrow-to-right'/>
          <span className={styles.metaActionText}>Last Page</span>
        </Link>
      </div>
    </div>)
  }

  const classNames = cx(
    styles.thread,
    isPromoted ? styles.promoted : '',
  )

  if (user) {
    const isSafeBrowsing = user.flags.some((flag: string) => ['safe_browsing', 'restricted_content'].includes(flag))
    const isRestricted = tags.includes('restricted-content')

    if (
      isSafeBrowsing &&
      isRestricted &&
      !showRestricted
    ) {
      const {
        user_avatar: avatar,
      } = thread
      const coverImage = cover ? cover : avatar

      return (<div
        className={cx(classNames, styles.restricted)}
        style={{
          backgroundImage: `url(${buildImageUrl({ image: coverImage, w: 1920, h: 1080, q: 100, b: 100 })})`,
        }}>
        <button
          className={styles.reveal}
          onClick={onShowRestrictedClick}>
          <i className={cx(styles.icon, 'fa-eye')}/>
          Reveal
        </button>
      </div>)
    }
  }

  if (isPromoted) {
    return (
      <div className={classNames}>
        {renderMeta()}
        <div className={styles.promotedGradient}/>
        <div
          className={styles.promotedBackground}
          style={{
            backgroundImage: `url(${buildImageUrl({ image: cover, w: 1080, h: 1920, q: 100 })})`,
          }}/>
      </div>
    )
  }

  return (
    <div className={classNames}>
      {renderMeta()}
      {renderCover()}
    </div>
  )
}

const styles = {
  thread: cx(
    flex({
      direction: {
        base: 'column-reverse',
        lg: 'row',
      },
      gap: 4,
    }),
    css({
      borderBottom: '1px solid token(colors.foregroundAlt)',
      flex: 1,
      maxHeight: '100vh',
      pb: 8,
      pos: 'relative',
      w: '100%',
      '&:has(> .cover) .meta': {
        flex: 2,
      },
    }),
  ),
  promoted: cx(
    css({
      bg: 'background',
      borderBottom: 'none !important',
      borderRadius: 'xl',
      display: 'flex',
      flex: {
        base: 'none',
      },
      height: {
        base: 'calc(66vh - 5.5rem)',
        lg: 'calc(100vh - 4rem - var(--nav-top))',
      },
      gap: {
        base: 8,
        lg: 0,
      },
      overflow: 'hidden',
      p: 0,
      pb: 0,
      position: {
        lg: 'sticky !important' as any,
      },
      top: {
        lg: 'calc(2rem + var(--nav-top))',
      },
      zIndex: 20,
      '& .cover': {
        hideFrom: 'lg',
      },
      '& .meta': {
        display: 'flex',
        h: '100%',
        justifyContent: 'flex-end',
        mb: 0,
        px: {
          base: 6,
          lg: 12,
        },
        py: {
          base: 8,
          lg: 16,
        },
      },
      '& .title': {
        fontSize: {
          base: '1.75rem',
          lg: '2.5rem',
        },
      },
      '& .action, & .title, & .date, & a': {
        color: 'white',
      },
    }),
  ),
  promotedGradient: css({
    bg: 'linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.75) 50%, rgba(0,0,0,0) 100%)',
    h: '100%',
    position: 'absolute',
    w: '100%',
    zIndex: 1,
  }),
  promotedBackground: css({
    backgroundPosition: 'center center !important',
    backgroundSize: 'cover',
    h: '100%',
    position: 'absolute',
    w: '100%',
    zIndex: 0,
  }),
  restricted: cx(
    flex({
      align: 'center',
      justify: 'center',
    }),
    css({
      backgroundSize: 'cover',
      borderBottom: 'none !important',
      borderRadius: 'md',
      minHeight: '8rem',
      overflow: 'hidden',
      pb: 0,
    }),
  ),
  cover: css({
    borderRadius: 'md',
    flex: 1,
    overflow: 'hidden',
  }),
  meta: cx(
    vstack({
      alignItems: 'start',
    }),
    css({
      borderBottomRadius: 'md',
      flex: 1,
      pos: 'relative',
      w: '100%',
      zIndex: 101,
    }),
  ),
  avatar: css({
    h: {
      base: 6,
      lg: 8,
    },
    flex: 'none',
    w: {
      base: 6,
      lg: 8,
    },
  }),
  metaThread: hstack({
    alignItems: 'start',
    gap: 4,
    w: '100%',
  }),
  metaThreadContent: vstack({
    alignItems: 'start',
    gap: 2,
    ml: {
      base: '-2.5rem',
      lg: 0,
    },
    w: '100%',
  }),
  metaUserWrapper: cx(
    flex({
      alignItems: 'center',
      gap: 1,
    }),
    css({
      h: {
        base: 6,
        lg: 'auto',
      },
      ml: {
        base: 8,
        lg: 0,
      },
    }),
  ),
  metaUsername: css({
    color: 'text',
    maxW: {
      base: '7rem',
      lg: '100%',
    },
    minW: 0,
    _hover: {
      color: 'linkHover',
    },
  }),
  metaTitle: css({
    color: 'link',
    fontWeight: 700,
    w: '100%',
    wordBreak: 'break-word',
    _hover: {
      color: 'linkHover',
    },
  }),
  metaDate: css({
    color: 'textAlt',
  }),
  metaRead: css({
    color: 'textAlt !important',
    fontWeight: '400 !important',
  }),
  metaActions: hstack({
    alignItems: 'center',
    gap: 4,
    ml: {
      lg: 12,
    },
    w: {
      base: '100%',
      lg: 'auto',
    },
  }),
  metaAction: css({
    bg: 'none',
    border: 'none',
    color: 'link',
    cursor: 'pointer',
    fontWeight: '400',
    p: 0,
    _hover: {
      color: 'linkHover',
    },
  }),
  metaCount: css({
    gap: 2,
  }),
  metaNoAction: hstack({
    color: 'link',
    gap: 1,
  }),
  metaLastReply: hstack({
    flex: 1,
    flexWrap: {
      base: 'wrap',
      lg: 'none',
    },
    gap: 0,
  }),
  metaActionText: css({
    display: 'none',
  }),
  lastReplyAvatar: css({
    h: '4 !important',
    mr: '1 !important',
    w: {
      base: '4 !important',
      lg: 8,
    },
  }),
  lastReplyUser: hstack({
    alignItems: 'center',
    gap: 1,
    minW: 0,
  }),
  lastReplyUsername: css({
    display: 'block',
    minW: 0,
    truncate: true,
  }),
  lastReplyDate: css({
    flex: 'none',
  }),
  reveal: css({
    bg: 'text',
    border: 'none',
    borderRadius: 'full',
    color: 'foreground',
    px: 4,
    py: 2,
    _hover: {
      bg: 'foregroundAlt',
      color: 'text',
    },
  }),
  icon: cx(
    'fas',
    css({
      mr: 2,
    }),
  ),
  dot: cx(
    'fas',
    css({
      color: 'highlight',
      fontSize: '0.375rem',
    }),
  ),
}

export default GridItem
