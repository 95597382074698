import Avatar from '@components/shared/UserAvatar'
import FavoriteButton from '@components/shared/FavoriteButton'
import Link from 'next/link'
import React, {
  ReactElement,
} from 'react'
import ThreadItem from '@components/shared/ThreadItem'
import moment from 'moment'
import { css, cx } from 'styled-system/css'
import { hstack } from 'styled-system/patterns'
import type { FavoriteThreadType } from '@/types/threads'
import type { FeedType } from '@/types/feed'

type Props = {
  thread: FavoriteThreadType|FeedType
}

const Thread = ({
  thread,
}: Props): ReactElement => {
  const renderLastReply = ({
    count,
    id,
    is_favorite: isFavorite,
    last_reply: {
      date,
      username,
      user_avatar: avatar,
      user_slug: lastReplyUserSlug,
    },
    slug,
  }: FeedType): ReactElement => {
    const lastPage = Math.max(Math.ceil(count / 50), 1)
    return (
      <div className={styles.lastReply}>
        <span className={styles.lastReplyCount}>
          <i className='fal fa-comment-lines'/> {count}
          <span className={styles.lastReplyCountText}>Replies</span>
        </span>
        <div className={cx('meta', styles.lastReplyMeta)}>
          <Avatar
            image={avatar}
            size='16'/>
          <Link
            className={styles.lastReplyUsername}
            href={`/u/${lastReplyUserSlug}`}
            prefetch={false}>
            <strong className={styles.lastReplyUsername}>{username}</strong>
          </Link> <span className={styles.lastReplyDate}>{moment(date).fromNow()}</span>
        </div>
        <div className={styles.lastReplyActions}>
          <FavoriteButton
            id={id}
            isFavorite={isFavorite}
            isRead/>
          <Link
            className={styles.lastReplyLastPage}
            href={`/s/${slug}/t/${id}/page/${lastPage}`}
            prefetch={false}>
            <i className={cx(styles.icon, 'fa-arrow-to-right')}/>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <ThreadItem
      {...thread as any}
      extraClassNames={styles.thread}
      lastReply={renderLastReply(thread)}
      url={`/s/${thread.slug}/t/${thread.id}`}/>
  )
}

const styles = {
  thread: css({
    gridColumn: 'span 4 / auto',
  }),
  lastReply: css({
    alignItems: 'center',
    display: 'grid',
    flex: 1,
    gap: {
      base: 4,
      lg: 2,
    },
    gridTemplateColumns: 'minmax(0, auto) minmax(0, 100fr) minmax(0, auto)',
  }),
  lastReplyMeta: cx(
    hstack({
      gap: 2,
    }),
    css({
      color: 'textAlt',
    })
  ),
  lastReplyCount: hstack({
    gap: 2,
  }),
  lastReplyCountText: css({
    display: 'none',
  }),
  lastReplyUser: hstack({
    alignItems: 'center',
    minW: 0,
  }),
  lastReplyUsername: css({
    truncate: true,
  }),
  lastReplyDate: css({
    flex: 'none',
  }),
  lastReplyActions: hstack({
    gap: 2,
  }),
  lastReplyLastPage: cx(
    css({
      _hover: {
        color: 'link',
      },
    }),
  ),
  icon: cx(
    'fal',
    css({
      display: 'block',
      textAlign: 'center',
      w: '6 !important',
    }),
  ),
}

export default Thread
