'use client'

import React, {
  ReactElement,
} from 'react'
import { buildImageUrl } from '@utils/functional'
import { circle } from 'styled-system/patterns'
import { css, cx } from 'styled-system/css'
import { useCurrentUser } from '@hooks'

type Props = {
  classNames?: string
  image: string
  size?: string
}

const Avatar = ({
  classNames = '',
  image,
  size = '8',
}: Props): ReactElement => {
  const user = useCurrentUser()

  const className = cx(
    cx(
      user?.settings && user?.settings.avatars === 'rounded'
        ? css({ borderRadius: 'md' }) : circle({ size }),
      css({
        position: 'relative',
        w: size,
        zIndex: 10,
      }),
      classNames,
    )
  )

  return (
    <img
      className={className}
      height={size}
      src={buildImageUrl({ image, w: 240, h: 240 })}
      width={size} />
  )
}

export default Avatar
