import Avatar from '@components/shared/UserAvatar'
import Link from 'next/link'
import React, {
  ReactElement,
  ReactNode,
} from 'react'
import moment from 'moment'
import { ThreadListItemType } from 'src/types/threads'
import { css, cx } from 'styled-system/css'
import { flex, hstack, vstack } from 'styled-system/patterns'

type Props = ThreadListItemType & {
  extraClassNames?: string
  lastReply?: ReactNode
  type?: string
  url: string
}

const ThreadItem = ({
  extraClassNames = '',
  id,
  is_read: isRead,
  date,
  lastReply = null,
  title,
  type,
  user_avatar: avatar,
  user_slug: slug,
  username,
  url,
}: Props): ReactElement => {
  const renderType = (type: string): string => {
    switch (type) {
      case 'quote.post':
        return 'Quoted by '
      case 'mention.post':
      case 'mention.thread':
        return 'Mentioned by '
      default:
        return ''
    }
  }

  return (
    <div
      className={cx(styles.link, extraClassNames)}
      key={id}>
      <div className={styles.container}>
        <div className={styles.metaThread}>
          <Avatar
            classNames={styles.avatar}
            image={avatar}/>
          <div className={styles.metaThreadContent}>
            <div className={styles.metaUserWrapper}>
              <span className={cx('meta', isRead ? styles.metaRead : '')}>
                {renderType(type as string)}<Link
                  href={`/u/${slug}`}
                  prefetch={false}><strong>{username}</strong></Link> {moment(date).fromNow()}
              </span>
            </div>
            <Link
              href={url}
              prefetch={false}><h4 className={cx('title', styles.title, isRead ? styles.metaRead : '')}>{title.replace(/&amp;/g, '&')}</h4></Link>
          </div>
        </div>
        {
          lastReply
            ? (
              <div className={styles.lastReplyContainer}>
                <i className={cx('dot', styles.dot, 'fa-circle', isRead ? styles.dotRead : '')}/>
                {lastReply}
              </div>
            )
            : null
        }
      </div>
    </div>
  )
}

const styles = {
  link: cx(
    'peer',
    flex(),
    css({
      mr: '-1rem',
      w: '100%',
      _hover: {
        bg: {
          lg: 'highlight',
        },
        color: {
          lg: 'text',
        },
        borderRadius: 'md',
        '& a': {
          color: {
            lg: 'text',
          },
        },
        '& > .container': {
          borderBottomColor: {
            lg: 'transparent',
          },
        },
        '& .title, & .dot, & .favorite': {
          color: {
            lg: 'inherit !important',
          },
        },
        '& .dotRead': {
          color: {
            lg: 'transparent !important',
          },
        },
      },
      '&:has(+ .peer:hover) > .container': {
        borderBottomColor: {
          lg: 'transparent',
        },
      },
    }),
  ),
  container: cx(
    'container',
    css({
      display: {
        lg: 'grid',
      },
      gridTemplateColumns: {
        lg: 'minmax(0, 70fr) minmax(0, 30fr)',
      },
      layerStyle: 'borderBottom',
      position: 'relative',
      px: {
        lg: 4,
      },
      py: 4,
      w: '100%',
    })
  ),
  meta: cx(
    vstack({
      alignItems: 'start',
    }),
    css({
      borderBottomRadius: 'md',
      flex: 1,
      pos: 'relative',
      w: '100%',
      zIndex: 101,
    }),
  ),
  avatar: css({
    h: {
      base: 6,
      lg: 8,
    },
    flex: 'none',
    w: {
      base: 6,
      lg: 8,
    },
  }),
  metaThread: hstack({
    alignItems: 'start',
    gap: 4,
    w: '100%',
  }),
  metaThreadContent: vstack({
    alignItems: 'start',
    gap: 2,
    ml: {
      base: '-2.5rem',
      lg: 0,
    },
    w: '100%',
  }),
  metaUserWrapper: cx(
    flex({
      alignItems: 'center',
      gap: 1,
    }),
    css({
      fontSize: '0.875rem',
      h: {
        base: 6,
        lg: 'auto',
      },
      ml: {
        base: 8,
        lg: 0,
      },
    }),
  ),
  title: css({
    fontWeight: 700,
  }),
  metaRead: css({
    color: 'textAlt !important',
    fontWeight: '400 !important',
  }),
  extra: css({
    alignItems: 'center',
    display: 'flex',
    gap: 4,
    justifyContent: 'space-between',
  }),
  lastReplyContainer: hstack({
    alignItems: 'center',
    gap: 2,
    mt: {
      base: 4,
      lg: 0,
    },
  }),
  dot: cx(
    'fas',
    css({
      color: 'highlight',
      fontSize: '0.375rem',
    }),
  ),
  dotRead: cx(
    'dotRead',
    css({
      color: 'transparent',
      display: {
        base: 'none !important',
        lg: 'block !important',
      },
    }),
  ),
}

export default ThreadItem
